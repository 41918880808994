<template>
  <div>

    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="userData.avatar"
          :text="avatarText(userData.name)"
          :variant="`light-${resolveUserRoleVariant(userData.role)}`"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ userData.name }}
      </h4>
      <div class="d-flex flex-wrap">
        <b-button
          variant="primary"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="inputImageRenderer"
          >
          <span class="d-none d-sm-inline">Update</span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
        <b-button
          variant="outline-secondary"
          class="ml-1"
        >
          <span class="d-none d-sm-inline">Remove</span>
          <feather-icon
            icon="TrashIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
      </div>
    </b-media>

    <b-form
      class="mt-1"
      @submit.prevent="onSubmit"
    >

      <!-- Header: Private Data -->
      <div class="d-flex mt-2">
        <feather-icon
          icon="LockIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          Private Data
        </h4>
      </div>
      <!-- User Info: Input Fields -->
      <b-row>
        <!-- Field: Full Name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Name"
            label-for="full-name"
          >
            <b-form-input
              id="full-name"
              v-model="userData.name"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="userData.email"
              type="email"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Role -->
        <b-col
          cols="12"
          sm="6"
          md="3"
        >
          <b-form-group
            label="User Role"
            label-for="user-role"
          >
            <v-select
              v-model="userData.role_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="$store.state.currentUser.role.code === 'su' ? roleOptions.filter(el => {
                return el.code === 'sp'|| el.code === 'su'
              }) : roleOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="user-role"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Status -->
        <b-col
          cols="12"
          sm="6"
          lg="3"
        >
          <b-form-group class="mt-1">
            <b-form-checkbox
              id="status"
              v-model="userData.status"
              :value="1"
              :aria-checked="true"
            >
              Active Account
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <!-- Field: Password -->
        <b-col
          class="d-flex justify-content-between"
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-checkbox
            v-model="userData.change_password"
            class="mt-1"
            :value="true"
          >
            Change Password
          </b-form-checkbox>
          <b-form-group
            v-if="userData.change_password"
            label="Change Password"
            label-for="new_password"
          >
            <b-form-input
              id="new_password"
              v-model="userData.new_password"
              type="password"
            />
          </b-form-group>
        </b-col>

      </b-row>

      <!-- Header: Personal Info -->
      <div class="d-flex mt-2">
        <feather-icon
          icon="UserIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          Personal Information
        </h4>
      </div>

      <!-- Form: Personal Info Form -->
      <b-row class="mt-1">
        <!-- Field: Join Date -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Join Date"
            label-for="birth-date"
          >
            <flat-pickr
              v-model="userData.birthdate"
              class="form-control"
              :config="{ dateFormat: 'Y-m-d'}"
              placeholder="YYYY-MM-DD"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Phone -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Phone"
            label-for="phone"
          >
            <b-form-input
              id="phone"
              v-model="userData.phone"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Gender -->
        <b-col
          cols="12"
          sm="6"
          lg="4"
        >
          <b-form-group
            label="Gender"
            label-for="gender"
            label-class="mb-1"
          >
            <b-form-radio-group
              id="gender"
              v-model="userData.gender"
              :options="genderOptions"
            />
          </b-form-group>
        </b-col>

        <!-- Company users -->
        <b-col
          v-if="userData.role_id === roleOptions.find(o => o.code === 'su').value"
          cols="12"
          md="4"
        >
          <b-form-group
            label="Company"
            label-for="company_id"
          >
            <v-select
              v-model="userData.company_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="companyUsers"
              :reduce="val => val.value"
              :clearable="false"
              input-id="company_id"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Company  -->
        <b-col
          v-if="userData.role_id === roleOptions.find(o => o.code === 'su').value && userData.company_id === 0"
          cols="12"
          md="4"
        >
          <b-form-group
            label="Company Name"
            label-for="company_name"
          >
            <b-form-input
              id="company_name"
              v-model="userData.company_name"
              autofocus
              trim
            />
          </b-form-group>
        </b-col>

        <!-- Field: Industry Name  -->
        <b-col
          v-if="userData.role_id === roleOptions.find(o => o.code === 'su').value"
          cols="12"
          md="4"
        >
          <b-form-group
            label="Industry name"
            label-for="industry_name"
          >
            <v-select
              v-model="userData.industry_name"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="industryNames"
              :reduce="val => val.value"
              :clearable="false"
              input-id="industry_name"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Address  -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Address"
            label-for="address"
          >
            <b-form-textarea
              id="address"
              v-model="userData.address"
              trim
              placeholder="Current address"
              rows="2"
            />
          </b-form-group>
        </b-col>

      </b-row>

      <!-- PERMISSION TABLE
    <b-card
      no-body
      class="border mt-1"
    >
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon
            icon="LockIcon"
            size="18"
          />
          <span class="align-middle ml-50">Permission</span>
        </b-card-title>
      </b-card-header>
      <b-table
        striped
        responsive
        class="mb-0"
        :items="permissionsData"
      >
        <template #cell(module)="data">
          {{ data.value }}
        </template>
        <template #cell()="data">
          <b-form-checkbox :checked="data.value" />
        </template>
      </b-table>
    </b-card>
    -->

      <!-- Action Buttons -->
      <b-button
        type="submit"
        variant="primary"
        :disabled="disableBtn"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1 mt-2"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      >
        Save Changes
      </b-button>
      <b-button
        class="mt-2"
        variant="outline-secondary"
        type="reset"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      >
        Reset
      </b-button>

    </b-form>

  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BFormRadioGroup, BFormTextarea, BFormCheckbox,
} from 'bootstrap-vue'
import store from '@/store'
import { avatarText } from '@core/utils/filter'
import genders from '@/@fake-db/data/other/genders'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import industryNames from '@/@fake-db/data/other/industry_name_options'
import { ref } from '@vue/composition-api'
import useUsersList from '../users-list/useUsersList'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormRadioGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BForm,
    vSelect,
    flatPickr,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    disableBtn: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      industryNames,
    }
  },
  setup(props, { emit }) {
    const { resolveUserRoleVariant } = useUsersList()

    const roleOptions = ref([])
    const companyUsers = ref([])
    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.avatar = base64
    })

    const getRoles = () => {
      store.dispatch('app-user/getRoles')
        .then(response => {
          roleOptions.value = response.data.data
        })
    }
    getRoles()

    const getCompanyUsers = () => {
      store.dispatch('app-user/getCompanyUsers')
        .then(response => {
          companyUsers.value = response.data.data
          companyUsers.value.push({
            value: 0,
            label: 'Other',
          })
        })
    }
    getCompanyUsers()

    const onSubmit = () => {
      emit('submit-form')
    }

    const genderOptions = genders

    return {
      resolveUserRoleVariant,
      avatarText,
      roleOptions,
      genderOptions,
      companyUsers,
      onSubmit,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
